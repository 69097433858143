<template>
    <div>
        <h1 class="text-center"><strong>{{$t('campaigns.briefDetail')}}</strong></h1>
        <b-row>
            <b-col
              md="12"
              lg="6"
            >
                <b-card>
                    <h4>{{$t('campaigns.imagesCampaging')}}</h4>
                    <div
                        style="width: 100%; display: flex;"
                    >
                        <div style="width: 48%; margin-right: 4%;">
                        <label for="wildcard">{{$t('campaigns.uploadLogo')}}</label>
                        <b-form-file
                            id="logo"
                            v-model="logo"
                            accept=".jpeg, .jpg, .png"
                            v-on:change="handleFileUploadLogo()"
                            :placeholder="$t('campaigns.selectLogo')"
                            :browse-text="$t('campaigns.search')"
                        />
                        </div>
                        <div style="width: 48%">
                        <label for="wildcard">{{$t('campaigns.uploadCover')}}</label>
                        <b-form-file
                            id="cover"
                            ref="cover"
                            accept=".jpeg, .jpg, .png"
                            v-on:change="handleFileUpload()"
                            :placeholder="$t('campaigns.selectCover')"
                            :browse-text="$t('campaigns.search')"
                        />
                        </div>
                    </div>
                    <div class="divisor" v-if="LSfirstName || LSlastName"/>
                    <div v-if="LSfirstName || LSlastName">
                        <h4>{{$t('campaigns.applicantName')}}</h4>
                        <h5><strong> {{LSfirstName}} {{LSlastName}}</strong></h5>
                    </div>
                    <div class="divisor" />
                    <div>
                        <h4>{{$t('campaigns.dateApplicant')}}</h4>
                        <h5><strong> {{fecha}}</strong></h5>
                    </div>
                    <div class="divisor" />
                    <div>
                        <h4
                            class="text-left font-weight-bolder"
                        >{{$t('campaigns.budget')}}</h4>
                        <b-input-group
                        prepend="$"
                        >
                            <cleave
                            v-model="budget"
                            ref="budget"
                            class="form-control"
                            :raw="false"
                            :options="options.number"
                            placeholder="10,000"
                            :maxlength="10"
                            @input="validateBudget"
                            />
                        </b-input-group>
                    </div>
                </b-card>
            </b-col>
            <b-col
              md="12"
              lg="6"
            >
                <b-card>
                    <h4>{{$t('campaigns.brand')}}</h4>
                    <b-form-group>
                        <v-select
                        v-model="selectedBrand"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="nameBrand"
                        :clearable="false"
                        :searchable="false"
                        />
                    </b-form-group>
                    <div class="divisor" />
                    <h5
                        class="text-left font-weight-bolder"
                    >{{$t('campaigns.campaignName')}}</h5>
                    <b-input-group>
                        <b-input-group-prepend is-text>
                        <feather-icon icon="SearchIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                        id="name"
                        v-model="name"
                        ref="name"
                        placeholder="Nombre de la Campaña"
                        autocomplete="off"
                        maxlength="100"
                        />
                    </b-input-group>
                    <div class="divisor" />
                    <h5
                        class="text-left font-weight-bolder"
                    >{{$t('campaigns.campaignValidity')}}</h5>
                    <div
                        style="width: 100%; display: flex;"
                    >
                        <div style="width: 48%; margin-right: 4%;">
                            <label for="fecha-inicio">{{$t('campaigns.initDate')}}</label>
                            <b-form-datepicker
                            id="fecha-inicio"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            v-model="init_date"
                            class="mb-1"
                            :placeholder="$t('campaigns.initDate')"
                            />
                        </div>
                        <div style="width: 48%">
                            <label for="fecha-final">{{$t('campaigns.endDate')}}</label>
                            <b-form-datepicker
                            id="fecha-final"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            v-model="end_date"
                            class="mb-1"
                            :placeholder="$t('campaigns.endDate')"
                            />
                        </div>
                    </div>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <h4
                        class="text-left font-weight-bolder"
                    >{{$t('campaigns.description')}}</h4>
                    <b-form-checkbox
                        ref="description"
                        v-model="descriptionCheck"
                        class="custom-control-primary text-left mt-1 mb-1"
                    >
                        {{$t('campaigns.showMessage')}}
                    </b-form-checkbox>
                    <b-form-group>
                        <rich-editor @changeText="changeDescription" :label="'description'" :key="change_language"/>
                    </b-form-group>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card :key="message_invitation">
                    <h4
                        class="text-left font-weight-bolder"
                    >{{$t('campaigns.message')}}</h4>
                    <b-form-checkbox
                        v-model="messageCheck"
                        class="custom-control-primary text-left mt-1 mb-1"
                    >
                        {{$t('campaigns.showMessage')}}
                    </b-form-checkbox>
                    <b-form-group>
                      <rich-editor @changeText="changeLink" :label="'link'" :key="change_language" :prev_text="message_invitation"/>
                    </b-form-group>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <h4
                        class="text-left font-weight-bolder"
                    >{{$t('campaigns.objective')}}</h4>
                    <b-form-checkbox
                        v-model="objectifCheck"
                        class="custom-control-primary text-left mt-1 mb-1"
                    >
                        {{$t('campaigns.showMessage')}}
                    </b-form-checkbox>

                    <b-form-group>
                      <rich-editor @changeText="changeObjective" :label="'objective'" :key="change_language"/>
                    </b-form-group>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <h4
                        class="text-left font-weight-bolder"
                    >{{$t('campaigns.comunicationObjective')}}</h4>
                    <b-form-checkbox
                        v-model="comunicationCheck"
                        class="custom-control-primary text-left mt-1 mb-1"
                    >
                        {{$t('campaigns.showMessage')}}
                    </b-form-checkbox>
                    <b-form-group>
                        <rich-editor @changeText="changeComunication" :label="'comunication'" :key="change_language"/>
                    </b-form-group>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <h4
                        class="text-left font-weight-bolder"
                    >{{$t('campaigns.consumer')}}</h4>
                    <b-form-checkbox
                        v-model="consumerCheck"
                        class="custom-control-primary text-left mt-1 mb-1"
                    >
                        {{$t('campaigns.showMessage')}}
                    </b-form-checkbox>
                    <b-form-group>
                        <b-form-input
                        id="basicInput"
                        v-model="consumer"
                        placeholder="Link de Box con el consumer profile - Género, Edad, NSE, ubicación geográfica"
                        />
                    </b-form-group>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <h4
                        class="text-left font-weight-bolder"
                    >{{$t('campaigns.insights')}}</h4>
                    <b-form-checkbox
                        v-model="insightsCheck"
                        class="custom-control-primary text-left mt-1 mb-1"
                    >
                        {{$t('campaigns.showMessage')}}
                    </b-form-checkbox>
                    <b-form-group>
                      <rich-editor @changeText="changeInsights" :label="'insights'" :key="change_language"/>
                    </b-form-group>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <h4
                        class="text-left font-weight-bolder"
                    >{{$t('campaigns.influencers')}}</h4>
                    <b-form-checkbox
                        v-model="whyCheck"
                        class="custom-control-primary text-left mt-1 mb-1"
                    >
                        {{$t('campaigns.showMessage')}}
                    </b-form-checkbox>
                    <b-form-group>
                      <rich-editor @changeText="changeInfluencers" :label="'influencers'" :key="change_language"/>
                    </b-form-group>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <h4
                        class="text-left font-weight-bolder"
                    >{{$t('campaigns.personality')}}</h4>
                    <b-form-checkbox
                        v-model="personalityCheck"
                        class="custom-control-primary text-left mt-1 mb-1"
                    >
                        {{$t('campaigns.showMessage')}}
                    </b-form-checkbox>
                    <b-form-group>
                      <rich-editor @changeText="changePersonality" :label="'personality'" :key="change_language"/>
                    </b-form-group>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <h4
                        class="text-left font-weight-bolder"
                    >{{$t('campaigns.imagine')}}</h4>
                    <b-form-checkbox
                        v-model="imagineCheck"
                        class="custom-control-primary text-left mt-1 mb-1"
                    >
                        {{$t('campaigns.showMessage')}}
                    </b-form-checkbox>
                    <b-form-group>
                      <rich-editor @changeText="changeImagine" :label="'imagine'" :key="change_language"/>
                    </b-form-group>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <h4
                        class="text-left font-weight-bolder"
                    >{{$t('campaigns.requirements')}}</h4>
                    <b-form-checkbox
                        v-model="benefitsCheck"
                        class="custom-control-primary text-left mt-1 mb-1"
                    >
                        {{$t('campaigns.showMessage')}}
                    </b-form-checkbox>
                    <b-form-group>
                      <rich-editor @changeText="changeRequirements" :label="'requirements'" :key="change_language"/>
                    </b-form-group>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <h4
                        class="text-left font-weight-bolder"
                    >{{$t('campaigns.restrictions')}}</h4>
                    <b-form-checkbox
                        v-model="restrictionsCheck"
                        class="custom-control-primary text-left mt-1 mb-1"
                    >
                        {{$t('campaigns.showMessage')}}
                    </b-form-checkbox>
                    <b-form-group>
                      <rich-editor @changeText="changeRestrictions" :label="'restrictions'" :key="change_language"/>
                    </b-form-group>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <h4
                        class="text-left font-weight-bolder"
                    >{{$t('campaigns.exclusiveness')}}</h4>
                    <b-form-checkbox
                        v-model="exclusiviteCheck"
                        class="custom-control-primary text-left mt-1 mb-1"
                    >
                        {{$t('campaigns.showMessage')}}
                    </b-form-checkbox>
                    <b-form-radio-group
                    v-model="exclusibility"
                    :options="optionsExclusibility"
                    class="demo-inline-spacing mb-1"
                    value-field="value"
                    text-field="text"
                    disabled-field="disabled"
                    />
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <h4
                        class="text-left font-weight-bolder"
                    >{{$t('campaigns.resources')}}</h4>
                    <b-form-checkbox
                        v-model="influencerMedios"
                        class="custom-control-primary text-left mt-1 mb-1"
                    >
                        {{$t('campaigns.showMessage')}}
                    </b-form-checkbox>
                    <b-form-radio-group
                    v-model="medios"
                    :options="optionsMedios"
                    class="demo-inline-spacing mb-1"
                    value-field="value"
                    text-field="text"
                    disabled-field="disabled"
                    />
                </b-card>
            </b-col>
        </b-row>

        <!-- Custom fields -->
        <b-row 
          v-for="(item, index) in guidelines"
          :key="index"
        >
          <b-col>
            <b-card>
              <div class="d-flex align-items-center">
                <h4 class="text-left font-weight-bolder mb-0">{{item.front_name}}</h4>
                <b-button
                  variant="flat-danger"
                  class="btn-icon"
                  @click="deleteGuideline(index)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
              <b-form-checkbox
                :checked="item.show_link"
                :disabled="true"
                class="custom-control-primary text-left mt-1 mb-1"
              >
                {{$t('campaigns.showMessage')}}
              </b-form-checkbox>
              <b-form-group>
                <div v-html="item.form_value"></div>
              </b-form-group>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="text-center mb-2">
          <b-col>
            <b-button variant="success" @click="showModalAddCustom()"> {{$t('campaigns.addRequirement')}}</b-button>
          </b-col>
        </b-row>

        <modal-brandme size="xl" :show_modal="show_modal" :key="change_modal">
          <template #title>{{$t('campaigns.newRequirement')}}</template>
          <template #body>
            <new-requirement :campaign_uuid="$route.params.uuid" @closeModal="closeModalCustomField" @dataCustomField="dataCustomField"></new-requirement>
          </template>
        </modal-brandme>

        <b-row class="text-center">
            <b-col>
                <b-button
                variant="primary"
                v-on:click="createdList(selectedBrand.uuid)"
                >
                {{$t('campaigns.createCampaign')}}
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormFile, BInputGroup, BFormGroup, BInputGroupPrepend, BFormInput, BFormDatepicker, BFormCheckbox, BFormRadioGroup, BButton
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import config from '@/axiosCode'
import richEditor from '././richEditor'
import service from '@/services/campaign'
import { getDatev2 } from '@/libs/utils/times';
import ModalBrandme from '../components/modal/ModalBrandme.vue'
import newRequirement from './newRequirement.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormFile,
    BInputGroup,
    Cleave,
    BFormGroup,
    vSelect,
    BInputGroupPrepend,
    BFormInput,
    BFormDatepicker,
    BFormCheckbox,
    BFormRadioGroup,
    BButton,
    richEditor,
    ModalBrandme,
    newRequirement
  },
  mixins: [togglePasswordVisibility],
  data () {
    return {
      getDatev2,
      fecha:'',
      LSfirstName: localStorage.firstName,
      LSlastName: localStorage.lastName,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand'
        }
      },
      budget:'',
      selectedBrand: {title: this.$t('campaigns.selectBrand'), uuid: ''},
      nameBrand: [],
      name: '',
      init_date: '',
      end_date: '',
      description: '',
      message: '',
      objectif: '',
      comunication: '',
      consumer: '',
      insights: '',
      why: '',
      imagine: '',
      personality: '',
      benefits: '',
      restrictions: '',
      exclusibility: '',
      logo: '',
      cover: '',
      brand: '',
      componentKey: 0,
      optionsExclusibility: [
        { text: 'Solo mi categoría', value: 'Solo mi categoría', disabled: false },
        { text: 'Todo NARD', value: 'Todo NARD', disabled: false },
        { html: 'NARD y Bebidas Baja Graduación', value: 'NARD y Bebidas Baja Graduación', disabled: false },
        { text: 'NARD, Baja Graduación y Alcoholicas', value: 'NARD, Baja Graduación y Alcoholicas', disabled: false }
      ],
      customFields: this.getCustom(),
      medios: '',
      optionsMedios: [
        { text: 'Full Media', value: 'Full Media', disabled: false },
        { text: 'Solo digital', value: 'Solo digital', disabled: false }
      ],
      messageCheck: true,
      descriptionCheck: true,
      objectifCheck: true,
      comunicationCheck: true,
      consumerCheck: true,
      insightsCheck: true,
      whyCheck: true,
      personalityCheck: true,
      imagineCheck: true,
      benefitsCheck: true,
      restrictionsCheck: true,
      exclusiviteCheck: true,
      influencerMedios: true,
      change_language: false,
      message_invitation: '',
      show_modal: false,
      change_modal: false,
      guidelines: []
    }
  },
  created () {
    const d = new Date()
    const fecha = getDatev2(d)
    this.fecha = fecha
    const vue = this
    const auth = {
      Authorization: `Bearer ${localStorage.token}`
    }
    this.$http.get(`${config.apiCore}/api/v1/brands/`, {
      headers: auth
    })
      .then(response => {
        vue.nameBrand = vue.getBrand(response.data.response.results)
      })
      .catch(() => {
      })
    this.getMessageInvitation()
  },
  methods:{
    validateBudget() {
      const maxBudget = 10000000;
      const enteredBudget = parseInt(this.budget.replace(/,/g, ''), 10);
      if (enteredBudget > maxBudget) {
        this.budget = '10,000,000';
      }
    },
    deleteGuideline(index) {
      this.guidelines.splice(index, 1);
    },
    dataCustomField(data) {
      this.closeModalCustomField()
      this.guidelines.push(data)
    },
    closeModalCustomField() {
      this.show_modal = false
      this.change_modal = !this.change_modal
    },
    showModalAddCustom() {
      this.show_modal = true
      this.change_modal = !this.change_modal
    },
    getMessageInvitation() {
      service.getMessageInvitation()
        .then(response => {
          this.message_invitation = response.message;
        })
    },
    changeDescription(text) {
      this.description = text;
    },
    changeLink(text) {
      this.message = text;
    },
    changeObjective(text) {
      this.objectif = text;
    },
    changeComunication(text) {
      this.comunication = text;
    },
    changeInsights(text) {
      this.insights = text;
    },
    changeInfluencers(text) {
      this.why = text;
    },
    changePersonality(text) {
      this.personality = text;
    },
    changeImagine(text) {
      this.imagine = text;
    },
    changeRequirements(text) {
      this.benefits = text;
    },
    changeRestrictions(text) {
      this.restrictions = text;
    },
    forceRerender () {
      this.componentKey += 1
    },
    handleFileUpload () {
      this.cover = this.$refs.cover.$el.firstChild.files[0]
    },
    handleFileUploadLogo () {
      this.logo = this.$refs.logo.files[0]
    },
    getBrand (info) {
      const result = []
      info.forEach(i => {
        const obj = {
          title: i.name,
          uuid: i.uuid
        }
        result.push(obj)
      })
      return result
    },
    createdList(brand) {
      this.show = true
      const vue = this
      vue.customFields = vue.getCustom()
      this.guidelines.forEach(item => {
        vue.customFields.push(item)
      })
      const obj = JSON.stringify(JSON.stringify(this.customFields))

      let title_option = '';
      let text_option = '';
      if (this.description === '' || this.description === null) {
        title_option = this.$t('campaigns.descriptionRequired');
        text_option = this.$t('campaigns.textDescription');
      } else if (this.cover === '' || this.cover === null) {
        title_option = this.$t('campaigns.coverRequired');
        text_option = this.$t('campaigns.textCover');
      } else if (this.logo === '' || this.logo === null) {
        title_option = this.$t('campaigns.logoRequired');
        text_option = this.$t('campaigns.textLogo');
      } else if (this.name === '' || this.name === null) {
        title_option = this.$t('campaigns.nameRequired');
        text_option = this.$t('campaigns.textName');
      } else if (this.budget === '' || this.budget === null) {
        title_option = this.$t('campaigns.budgetRequired');
        text_option = this.$t('campaigns.textBudget');
      } else if (this.init_date === '' || this.init_date === null) {
        title_option = this.$t('campaigns.initDateRequired');
        text_option = this.$t('campaigns.textInitDate');
      } else if (this.end_date === '' || this.end_date === null) {
        title_option = this.$t('campaigns.endDateRequired');
        text_option = this.$t('campaigns.textEndDate');
      }
      if (title_option !== '' && text_option !== '') {
        loadToastificationContent().then((component) => {
          this.$toast({
            component: component.default,
            position: 'top-center',
            timeout: 5000,
            props: {
              title: title_option,
              icon: 'AlertCircleIcon',
              text: text_option,
              variant: 'danger'
            }
          })
        })
        if (this.description === '' || this.description === null) {
          this.$nextTick(() => {
            this.$refs.description.focus();
          });
        } else {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        return false;
      }
      const precio = this.budget.replace(/,/g, '');
      const formData = new FormData();
      formData.set('description', this.description);
      formData.append('cover', this.cover);
      formData.append('logo', this.logo);
      formData.set('name', this.name);
      formData.set('brand', this.brand);
      formData.set('budget', precio);
      formData.set('init_date', this.init_date);
      formData.set('end_date', this.end_date);
      formData.set('brand_reference', brand);
      formData.set('custom_fields', obj);
      if (this.message !== '') {
        formData.set('custom_message', this.message);
        formData.set('show_custom_message', this.messageCheck);
      } else {
        formData.set('custom_message', '');
        formData.set('show_custom_message', false);
      }

      const self = this;
      const auth = {
        Authorization: `Bearer ${localStorage.token}`
      };
      this.$http.post(`${config.apiCore}/api/v1/campaign/`,
        formData,
        {
          headers: auth
        })
        .then(result => {
          console.log(result)
          self.listCreatedConfirmation()
        })
        .catch(error => {
          console.log(error)
          console.log('FAILURE!!')
        })
    },
    getCustom () {
      const result = []
      const vue = this
      if (this.objectif !== '') {
        let obj = []
        obj = {
          form_value: vue.objectif,
          front_name: 'Objetivo de los influencers',
          show_link: this.objectifCheck,
          order: result.length,
          network: null,
          type_field: null
        }
        result.push(obj)
      }
      if (this.comunication !== '') {
        let obj = []
        obj = {
          form_value: vue.comunication,
          front_name: 'Objetivo de Comunicación',
          show_link: this.comunicationCheck,
          order: result.length,
          network: null,
          type_field: null
        }
        result.push(obj)
      }
      if (this.consumer !== '') {
        let obj = []
        obj = {
          form_value: vue.consumer,
          front_name: 'Consumer Profile',
          show_link: this.consumerCheck,
          order: result.length,
          network: null,
          type_field: null
        }
        result.push(obj)
      }
      if (this.insights !== '') {
        let obj = []
        obj = {
          form_value: vue.insights,
          front_name: 'Insights relevantes de la audiencia',
          show_link: this.insightsCheck,
          order: result.length,
          network: null,
          type_field: null
        }
        result.push(obj)
      }
      if (this.why !== '') {
        let obj = []
        obj = {
          form_value: vue.why,
          front_name: '¿Cuántos influencers necesitas y por qué?',
          show_link: this.whyCheck,
          order: result.length,
          network: null,
          type_field: null
        }
        result.push(obj)
      }
      if (this.personality !== '') {
        let obj = []
        obj = {
          form_value: vue.personality,
          front_name: 'Tono y Personalidad del Influencer',
          show_link: this.personalityCheck,
          order: result.length,
          network: null,
          type_field: null
        }
        result.push(obj)
      }
      if (this.imagine !== '') {
        let obj = []
        obj = {
          form_value: vue.imagine,
          front_name: '¿A quién te imaginas?',
          show_link: this.imagineCheck,
          order: result.length,
          network: null,
          type_field: null
        }
        result.push(obj)
      }
      if (this.benefits !== '') {
        let obj = []
        obj = {
          form_value: vue.benefits,
          front_name: 'Requerimientos y beneficios generales a solicitar',
          show_link: this.benefitsCheck,
          order: result.length,
          network: null,
          type_field: null
        }
        result.push(obj)
      }
      if (this.restrictions !== '') {
        let obj = []
        obj = {
          form_value: vue.restrictions,
          front_name: 'Consideraciones o Restricciones',
          show_link: this.restrictionsCheck,
          order: result.length,
          network: null,
          type_field: null
        }
        result.push(obj)
      }
      if (this.exclusibility !== '') {
        let obj = []
        obj = {
          form_value: vue.exclusibility,
          front_name: 'Exclusividad a solicitar',
          show_link: this.exclusiviteCheck,
          order: result.length,
          network: null,
          type_field: null
        }
        result.push(obj)
      }
      if (this.medios !== '') {
        let obj = []
        obj = {
          form_value: vue.medios,
          front_name: 'Medios donde se utilizará al influencer',
          show_link: this.influencerMedios,
          order: result.length,
          network: null,
          type_field: null
        }
        result.push(obj)
      }
      return result
    },
    listCreatedConfirmation () {
      this.$swal({
        title: this.$t('campaigns.aCreated'),
        text: this.$t('campaigns.aCreatedText'),
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false,
        heightAuto: false,
      }).then(result => {
        if (result.value) {
          this.$router.push('/campaigns')
        }
      })
    }
  },
  watch: {
    '$i18n.locale'() {
      this.change_language = !this.change_language;
      this.selectedBrand = {title: this.$t('campaigns.selectBrand'), uuid: ''};
    }
  }
}
</script>

<style>
    .divisor{
        margin: 3% 0;
        border-bottom: 1px solid #ddd
    }
</style>
